// extracted by mini-css-extract-plugin
export var warningBackgroundlight = "c_f";
export var warningBackgrounddark = "c_g";
export var warningBackgroundcustom = "c_h";
export var bottom = "c_j";
export var modal = "c_k";
export var container = "c_l";
export var btnWrapper = "c_m";
export var elementWrapper = "c_n";
export var titleWrapper = "c_p";
export var contentWrapper = "c_q";
export var btn = "c_r";
export var link = "c_s";
export var decline = "c_t c_r";
export var editor = "c_v";
export var row = "c_w";